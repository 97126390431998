var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "charter" }, [
    _c("div", { staticStyle: { width: "20%" } }, [_c("plateNavigation")], 1),
    _c(
      "div",
      { staticClass: "charter-content" },
      [
        _c("carousel"),
        _c("ranking", {
          attrs: { msg: _vm.msg },
          on: { collection: _vm.collection, subscribe: _vm.subscribe }
        }),
        _c("div", { staticClass: "new" }, [
          _c("div", { staticClass: "newImg" }, [_vm._v("最新图片")]),
          _c("div", { staticClass: "new-content" }, [
            _c(
              "div",
              { staticStyle: { width: "40%" } },
              [_c("newCarousel")],
              1
            ),
            _c(
              "div",
              { staticClass: "new-lists", staticStyle: { width: "55%" } },
              [_c("tab")],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "posting" }, [_vm._v("发帖")]),
        _c("div", { staticClass: "tag" }, [_c("tag")], 1),
        _c(
          "div",
          { staticClass: "area-list" },
          [
            _c("all", {
              on: {
                onChange: _vm.onChange,
                btnAll: _vm.btnAll,
                btnHot: _vm.btnHot,
                btnHotPost: _vm.btnHotPost,
                btnElite: _vm.btnElite
              }
            }),
            _c(
              "div",
              { staticClass: "list-item" },
              [
                _vm.isPattern
                  ? _c("imgPattren", {
                      ref: "img",
                      attrs: { pageNum: _vm.pageNum },
                      on: { getTotal: _vm.getTotal }
                    })
                  : _c("fontPattren", { ref: "font" }),
                _c("pagination", {
                  attrs: { totalNum: _vm.totalNum },
                  on: { onChangePagination: _vm.pagination }
                })
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "posting" }, [_vm._v("发帖")]),
        _c("VueUeditor", { on: { updateDeatil: _vm.updateDeatil } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }